import React, { ComponentProps } from 'react';
import { LuUser2 } from 'react-icons/lu';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { IScenarioData } from '@/features/scenario/mock-data.ts';
import { cn } from '@/lib/utils.ts';

import ScenarioActions from './ScenarioActions.tsx';

const Scenario = ({
    data,
    className,
    onClick,
}: {
    data: IScenarioData;
    className?: ComponentProps<'div'>['className'];
    onClick?: () => void;
}) => {
    const { id, name, lastUpdate, lastUpdateBy } = data;

    return (
        <div className='flex w-full items-center rounded-md border border-newDesign-divider bg-white'>
            <div
                onClick={onClick}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onClick();
                    }
                }}
                role={onClick ? 'button' : undefined}
                tabIndex={onClick ? 0 : undefined}
                className={cn(
                    'grid h-10 w-full items-center gap-3 px-2 py-1',
                    'grid-cols-[auto_1fr_auto]',
                    onClick ? 'cursor-pointer  hover:bg-opacity-35' : '',
                    className,
                )}>
                <TooltipWrapper text={`Last update by: ${lastUpdateBy}`}>
                    <Avatar className='flex size-6 items-center rounded-full bg-newDesign-background-text'>
                        <AvatarFallback className='bg-newDesign-background-text'>
                            <LuUser2 />
                        </AvatarFallback>
                    </Avatar>
                </TooltipWrapper>
                <label
                    htmlFor={id}
                    className={cn(
                        'overflow-hidden text-ellipsis whitespace-nowrap',
                        onClick
                            ? 'cursor-pointer hover:text-newDesign-secondary'
                            : '',
                    )}>
                    {name}
                </label>
            </div>
            <div className='flex h-full items-center gap-3 px-2 py-1'>
                <Separator orientation='vertical' />
                <p>{lastUpdate}</p>

                <ScenarioActions id={id} scenarioName={name} />
            </div>
        </div>
    );
};

export default Scenario;
