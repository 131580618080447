import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { TbClockCog } from 'react-icons/tb';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table.tsx';
import { cn } from '@/lib/utils.ts';

import type { IQuantityTimeSeriesEntry } from '../types.ts';

const DelayedDeploymentDialog = ({
    onSave,
    data,
    onChange,
    disabled,
}: {
    onSave?: () => void;
    data: IQuantityTimeSeriesEntry[];
    onChange?: (data: unknown) => void;
    disabled: boolean;
}) => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    disabled={disabled}
                    size='icon'
                    variant='text'
                    className='size-7 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                    <TbClockCog className={cn('size-5 ')} />
                </Button>
            </DialogTrigger>
            <DialogContent className='w-fit'>
                <DialogHeader>
                    <DialogTitle>Delayed Deployment</DialogTitle>
                    <DialogDescription>
                        {/* TODO: add description */}
                        _Insert description here_
                    </DialogDescription>
                </DialogHeader>
                <DelayedDeploymentTable data={data} />
                <DialogFooter>
                    <Button type='submit'>Confirm</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const delayedDeploymentColumnConfig: ColumnDef<IQuantityTimeSeriesEntry>[] = [
    {
        accessorKey: 'delay',
        header: () => (
            <p className='text-xs text-newDesign-text-secondary'>
                Elapsed Time (Months)
            </p>
        ),
    },
    {
        accessorKey: 'quantity',
        header: () => (
            <p className='text-xs text-newDesign-text-secondary'>Quantity</p>
        ),
    },
    {
        id: 'actions',
        header: () => (
            <p className='text-xs text-newDesign-text-secondary'>Delete</p>
        ),
        cell: () => (
            <Button
                size='icon'
                variant='text'
                className='group size-7 bg-transparent p-1 hover:bg-newDesign-error-light active:bg-newDesign-error disabled:cursor-default disabled:bg-opacity-10'>
                <RiDeleteBin2Line className='size-5 text-newDesign-error group-hover:text-white' />
            </Button>
        ),
    },
];

const DelayedDeploymentTable = ({
    data,
}: {
    data: IQuantityTimeSeriesEntry[];
}) => {
    const sortedData = data.sort((a, b) => a.delay - b.delay);

    const table = useReactTable({
        data: sortedData,
        columns: delayedDeploymentColumnConfig,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.delay.toString() + Math.random() + 1000,
    });

    return (
        <div className=' w-[450px]'>
            <Table maxHeight='max-h-[350px]'>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody className='w-full'>
                    {table.getRowModel().rows.map((row, index) => (
                        <TableRow
                            index={index}
                            data-state={row.getIsSelected() && 'selected'}
                            key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default DelayedDeploymentDialog;
