import { ColumnDef } from '@tanstack/react-table';
import React from 'react';

import { Currency } from '@bae/store';
import { formatPercent } from '@bae/utils';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';

import StatusBadge from '../shared/StatusBadge.tsx';
import type { IFteInput } from '../types';

export const columnConfig = (currency: Currency): ColumnDef<IFteInput>[] => [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        accessorKey: 'fteName',
        header: () => <p className='text-newDesign-text-secondary'>FTE</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper
                    pointerEvents={false}
                    text={ctx.row.getValue('fteName')}>
                    <p className='max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('fteName')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'annualExpense',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p>Fully Loaded</p>
                <p>Annual Expense</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {Number(ctx.row.getValue('annualExpense')).toLocaleString(
                        'en-US',
                        {
                            style: 'currency',
                            currency,
                        },
                    )}
                </p>
            );
        },
    },
    {
        accessorKey: 'expenseGrowth',
        header: () => (
            <p className='text-center text-newDesign-text-secondary'>
                Wage Growth Rate
            </p>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(ctx.row.getValue('expenseGrowth'), 2)}
                </p>
            );
        },
    },
    {
        accessorKey: 'quantity',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Number of FTEs</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('quantity')}
                </p>
            );
        },
    },
];
