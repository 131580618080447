import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { CiCircleInfo } from 'react-icons/ci';

import { formatPercent } from '@bae/utils';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';

import DelayedDeploymentDialog from '../shared/DelayedDeploymentDialog.tsx';
import StatusBadge from '../shared/StatusBadge.tsx';
import { IAccessPointInput } from '../types';

export const columnConfig: ColumnDef<IAccessPointInput>[] = [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        accessorKey: 'siteName',
        header: () => <p className='text-newDesign-text-secondary'>Site</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('siteName')}>
                    <p className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('siteName')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'name',
        header: () => <p className='text-newDesign-text-secondary'>Name</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('name')}>
                    <p className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('name')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'model',
        header: () => <p className='text-newDesign-text-secondary'>Model</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('model')}>
                    <p className='w-[80px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('model')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'currentQty',
        header: () => (
            <TooltipWrapper text='Quantity deployed at the delay 0, which is the present moment.'>
                <span className='text-center text-newDesign-text-secondary'>
                    <p className='whitespace-nowrap'>Current</p>
                    <p className='whitespace-nowrap'>
                        Quantity
                        <CiCircleInfo className='ml-1 inline-block size-3.5 stroke-1 text-newDesign-secondary' />
                    </p>
                </span>
            </TooltipWrapper>
        ),
        cell: (ctx) => {
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const delayZeroTimeSeries = qtyTimeSeries.find(
                (entry) => entry.delay === 0,
            );

            return delayZeroTimeSeries ? (
                <p className='mr-4 flex items-center justify-center'>
                    {delayZeroTimeSeries.quantity}
                </p>
            ) : (
                <TooltipWrapper text='See the delayed deployments.'>
                    <p className='mr-4 flex items-center justify-center'>
                        <CiCircleInfo className='size-4' />
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        id: 'delayedDeployments',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'> Delayed </p>
                <p>Deployments</p>
            </span>
        ),
        cell: (ctx) => {
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const delayZeroTimeSeries = qtyTimeSeries.find(
                (entry) => entry.delay === 0,
            );

            const singleZeroDelay =
                delayZeroTimeSeries && qtyTimeSeries.length === 1;

            return (
                <div className='group mr-4 flex items-center justify-center rounded-sm p-1'>
                    <DelayedDeploymentDialog
                        data={qtyTimeSeries}
                        disabled={singleZeroDelay}
                    />
                </div>
            );
        },
    },
    {
        accessorKey: 'siteTraffic',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Site Traffic</p>
                <p>(Mbps)</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('siteTraffic')}
                </p>
            );
        },
    },
    {
        accessorKey: 'clientsPerSite',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Wireless Clients</p>
                <p>per Site</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('clientsPerSite')}
                </p>
            );
        },
    },
    {
        accessorKey: 'avgTrafficPerAP',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Average Traffic</p>
                <p>per AP (Mbps)</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('avgTrafficPerAP')}
                </p>
            );
        },
    },
    {
        accessorKey: 'avgClientsPerAP',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Average Wireless</p>
                <p>Clients per AP (Mbps)</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('avgClientsPerAP')}
                </p>
            );
        },
    },
    {
        accessorKey: 'trafficGrowth',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Traffic Growth</p>
                <p>Rate</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(ctx.row.getValue('trafficGrowth'), 2)}
                </p>
            );
        },
    },
    {
        accessorKey: 'clientGrowth',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Wireless Client</p>
                <p>Growth Rate</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(ctx.row.getValue('clientGrowth'), 2)}
                </p>
            );
        },
    },
];
