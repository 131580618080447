import { Table as TableDef } from '@tanstack/react-table';

export const getShowingResultsPaginationText = <T>(table: TableDef<T>) => {
    const { pageIndex, pageSize } = table.getState().pagination;
    const totalItems = table.getFilteredRowModel().rows.length;

    if (totalItems === 0) {
        return null;
    }

    const firstItemIndex = pageIndex * pageSize + 1;
    const lastItemIndex = Math.min(totalItems, (pageIndex + 1) * pageSize);

    return `Showing ${firstItemIndex} - ${lastItemIndex} of ${totalItems} results`;
};
