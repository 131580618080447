import React from 'react';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';

import { Skeleton } from '@/components/ui/skeleton.tsx';
import Scenario from '@/features/scenario/components/Scenario.tsx';
import { IScenarioData } from '@/features/scenario/mock-data.ts';

const ScenariosContent = ({
    loading,
    scenarios,
}: {
    loading: boolean;
    scenarios: IScenarioData[];
}) => {
    const history = useHistory();

    if (loading)
        return (
            <>
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
                <Skeleton className='h-10 w-full rounded-md bg-newDesign-divider' />
            </>
        );

    if (!scenarios)
        return (
            <div className='flex h-[135px] w-full items-center justify-center'>
                <p className='text-2xl text-newDesign-black'>
                    No scenario fork created yet
                </p>
            </div>
        );

    const navigateToDashboardFactory = (id: string) => () => {
        history.push(`${RouteMap.scenarios.path}/${id}`);
    };

    return scenarios.map((scenario) => (
        <Scenario
            key={scenario.id}
            onClick={navigateToDashboardFactory(scenario.id)}
            data={scenario}
        />
    ));
};

export default ScenariosContent;
