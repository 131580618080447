import { GlobeIcon } from 'lucide-react';
import React from 'react';
import { PiGearLight } from 'react-icons/pi';

import OptionalLicenses from '@/app/shared-pages/Scenario/components/digital-twin/MistSettings/components/OptionalLicenses/OptionalLicenses.tsx';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from '@/components/ui/tabs.tsx';

import AccessPointsTable from './components/AccessPoints/AccessPointsTable.tsx';
import Configuration from './components/Configuration/Configuration.tsx';
import FteTable from './components/FTE/FteTable.tsx';
import SiteTable from './components/Sites/SitesTable.tsx';

const MistSettings = () => {
    return (
        <div className='mx-auto w-full max-w-screen-5xl'>
            <div className='mb-4 mt-2 flex gap-2'>
                <GlobeIcon className='size-6 text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>Mist Settings</h2>
            </div>
            <Tabs defaultValue='accessPoints'>
                <TabsList className='gap-2 bg-white'>
                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='accessPoints'>
                        Access Points
                    </TabsTrigger>
                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='fte'>
                        FTE
                    </TabsTrigger>
                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='sites'>
                        Sites
                    </TabsTrigger>
                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='licenses'>
                        Optional Licenses
                    </TabsTrigger>
                    <TabsTrigger
                        className='ring-1 ring-newDesign-divider data-[state=active]:text-newDesign-primary data-[state=active]:ring-newDesign-primary'
                        value='configuration'>
                        <PiGearLight className='mr-2 size-5' />
                        Configuration
                    </TabsTrigger>
                </TabsList>
                <TabsContent className='w-full' value='accessPoints'>
                    <AccessPointsTable />
                </TabsContent>
                <TabsContent value='fte'>
                    <FteTable />
                </TabsContent>
                <TabsContent value='sites'>
                    <SiteTable />
                </TabsContent>
                <TabsContent value='licenses'>
                    <OptionalLicenses />
                </TabsContent>
                <TabsContent value='configuration'>
                    <Configuration />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default MistSettings;
