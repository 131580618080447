import React from 'react';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Label } from '@/components/ui/label.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';

const OptionalLicenses = () => {
    return (
        <Accordion
            defaultValue='accessPoint'
            type='single'
            collapsible
            className='w-full'>
            <AccordionItem value='accessPoint'>
                <AccordionTrigger className='cursor-pointer rounded-lg border-none bg-newDesign-background p-2'>
                    <div className='flex w-full items-center justify-between pr-10'>
                        <p>Access Point</p>
                        <div className='flex items-center gap-2'>
                            <Label>License Term</Label>
                            <Select defaultValue='1'>
                                <SelectTrigger className='border-tag h-8 w-[100px]'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {['1', '3', '5'].map((term) => (
                                            <SelectItem key={term} value={term}>
                                                {term + ' Year'}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </AccordionTrigger>
                <AccordionContent>
                    <div className='flex flex-col gap-4 p-4'>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-MAN' disabled checked />
                            <Label
                                htmlFor='SUB-MAN'
                                className='peer-disabled:opacity-100'>
                                Wireless Assurance (SUB-MAN)
                            </Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-VNA' />
                            <Label htmlFor='SUB-VNA'>
                                Marvis Virtual Network Assistant (SUB-VNA)
                            </Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-ENG' />
                            <Label htmlFor='SUB-ENG'>
                                User Engagement (SUB-ENG)
                            </Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-AST' />
                            <Label htmlFor='SUB-AST'>
                                Asset Viability (SUB-AST)
                            </Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-PMA' />
                            <Label htmlFor='SUB-PMA'>
                                Premium Analytics (SUB-PMA)
                            </Label>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Checkbox id='SUB-AI' />
                            <Label htmlFor='SUB-AI'>
                                All-IN Bundle (SUB-AI)
                            </Label>
                        </div>
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default OptionalLicenses;
