import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { TbClockCog } from 'react-icons/tb';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';

import StatusBadge from '../shared/StatusBadge.tsx';
import type { ISiteInput } from '../types';

export const columnConfig: ColumnDef<ISiteInput>[] = [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        accessorKey: 'name',
        header: () => (
            <p className='text-newDesign-text-secondary'>Site Name</p>
        ),
        cell: (ctx) => {
            return (
                <TooltipWrapper
                    pointerEvents={false}
                    text={ctx.row.getValue('name')}>
                    <p className='max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('name')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'currentQty',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p>Current</p>
                <p>Quantity</p>
            </span>
        ),
        cell: (ctx) => {
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const delayZeroTimeSeries = qtyTimeSeries.find(
                (entry) => entry.delay === 0,
            );

            return (
                <p className='mr-4 flex items-center justify-center'>
                    {delayZeroTimeSeries ? delayZeroTimeSeries.quantity : '-'}
                </p>
            );
        },
    },
    {
        id: 'delayedDeployments',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'> Delayed </p>
                <p>Deployments</p>
            </span>
        ),
        cell: () => {
            // const data = ctx.row.original.qtyTimeSeries;

            return (
                <span className='hover:bg-newDesign-background-hover mr-4 flex items-center justify-center rounded-sm p-1 '>
                    {/*<ClientsTableActions data={data} />*/}
                    <TbClockCog className='size-5 text-newDesign-primary' />
                </span>
            );
        },
    },
    {
        accessorKey: 'country',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Country</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 text-center'>
                    {ctx.row.getValue('country')}
                </p>
            );
        },
    },
    {
        accessorKey: 'state',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>State</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 text-center'>{ctx.row.getValue('state')}</p>
            );
        },
    },
    {
        accessorKey: 'address',
        header: () => (
            <span className='text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Address</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>
                    {ctx.row.getValue('address')}
                </p>
            );
        },
    },
    {
        accessorKey: 'timeZone',
        header: () => (
            <span className='text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Time Zone</p>
            </span>
        ),
        cell: (ctx) => {
            return <p>{ctx.row.getValue('timeZone')}</p>;
        },
    },
];
