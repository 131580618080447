import type { IAccessPointInput, IQuantityTimeSeriesEntry } from '../types.ts';
import { faker } from '@faker-js/faker';

const getQtyTimeSeries = (): IQuantityTimeSeriesEntry[] => {
    return Array.from({ length: 5 }, () => ({
        delay: faker.number.int({ min: 0, max: 10 }),
        quantity: faker.number.int({ min: 1, max: 100 }),
    }));
};

export const defaultData: IAccessPointInput[] = Array.from(
    { length: 50 },
    () => ({
        status: faker.helpers.arrayElement(['modified', 'added', 'original']),
        siteName: faker.company.name(),
        name: faker.company.name(),
        model: faker.person.fullName(),
        qtyTimeSeries: getQtyTimeSeries(),
        siteTraffic: faker.number.float({
            min: 50,
            max: 500,
            fractionDigits: 2,
        }),
        clientsPerSite: faker.number.float({
            min: 50,
            max: 500,
            fractionDigits: 2,
        }),
        avgTrafficPerAP: faker.number.float({
            min: 50,
            max: 500,
            fractionDigits: 2,
        }),
        avgClientsPerAP: faker.number.float({
            min: 50,
            max: 500,
            fractionDigits: 2,
        }),
        trafficGrowth: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
        clientGrowth: faker.number.float({
            min: 0,
            max: 1,
            fractionDigits: 4,
        }),
    }),
);
