import { type IQuantityTimeSeriesEntry, ISiteInput } from '../types.ts';
import { faker } from '@faker-js/faker';

const getQtyTimeSeries = (): IQuantityTimeSeriesEntry[] => {
    return Array.from({ length: 5 }, () => ({
        delay: faker.number.int({ min: 0, max: 10 }),
        quantity: faker.number.int({ min: 1, max: 100 }),
    }));
};

export const defaultData: ISiteInput[] = Array.from({ length: 50 }, () => ({
    status: faker.helpers.arrayElement(['modified', 'added', 'original']),
    name: faker.company.name(),
    qtyTimeSeries: getQtyTimeSeries(),
    country: faker.location.countryCode(),
    state: faker.location.state({ abbreviated: true }),
    address: faker.location.streetAddress(),
    timeZone: faker.location.timeZone(),
}));
