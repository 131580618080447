import { IFteInput } from '../types.ts';
import { faker } from '@faker-js/faker';

export const defaultData: IFteInput[] = Array.from({ length: 50 }, () => ({
    status: faker.helpers.arrayElement(['modified', 'added', 'original']),
    fteName: faker.company.name(),
    annualExpense: faker.number.float({
        min: 500,
        max: 50000,
        fractionDigits: 2,
    }),
    expenseGrowth: faker.number.float({
        min: 0,
        max: 1,
        fractionDigits: 4,
    }),
    quantity: faker.number.float({
        min: 1,
        max: 50,
        fractionDigits: 2,
    }),
}));
